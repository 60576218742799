import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginLayout, MainLayout, PolicyLayout } from "./layout";
import {
  About,
  Contact,
  Dashboard,
  DeactivatedProfiles,
  DeleteProfile,
  Detail,
  Home,
  Login,
  MyPhotos,
  Payment,
  PrivacyPolicy,
  Profile,
  ProfileVerifications,
  Register,
  ReportIssue,
  Report,
  Search,
  SearchById,
  TermsAndConditions,
  AdminDashboard,
  ProfileUpgrade,
} from "./views";
import data from "./mocks/data.json";
import InactiveProfiles from "./views/admin/inactive-profiles";
import ProtectedRoutes from "./routes";
import ProfileHistory from "./views/profile-history";
import "./app.scss";
import "./antd-custom-style.scss";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* MainLayout contains Header, Main and Footer components */}
        <Route element={<MainLayout />}>
          <Route element={<ProtectedRoutes />}>
            <Route path="search" element={<Search />} />
          </Route>
          <Route path="payment" element={<Payment />} />
          <Route path="register" element={<Register />} />
          <Route path="contact" element={<Contact data={data} />}>
            <Route path=":id" element={<Detail />} />
          </Route>
          <Route path="/" element={<Home />} />
          <Route path="profile" element={<Profile />} />
          <Route path="reportissue" element={<ReportIssue />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="admin">
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route
              path="profile-verification"
              element={<ProfileVerifications />}
            />
            <Route path="profile-upgrade" element={<ProfileUpgrade />} />
            <Route path="report">
              <Route
                path="reported-account"
                element={<Report type="account" />}
              />
              <Route
                path="reported-issues"
                element={<Report type="issues" />}
              />
              <Route path="inactive-profiles" element={<InactiveProfiles />} />
            </Route>
            <Route
              path="deactivated-profiles"
              element={<DeactivatedProfiles />}
            />
            <Route path="delete-profile" element={<DeleteProfile />} />
          </Route>
          <Route path="/myphotos" element={<MyPhotos />} />
          <Route path="/about" element={<About />} />
          <Route path="/profile-history" element={<ProfileHistory />} />
        </Route>

        {/* LoginLayout contains Header and Main components */}
        <Route element={<LoginLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="search/:id" element={<SearchById />} />
        </Route>

        {/* PolicyLayout contain only Main component */}
        <Route element={<PolicyLayout />}>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
