import { useEffect, useState } from "react";
import { Card, Flex, Image, Modal, notification, Typography } from "antd";
import {
  HistoryOutlined,
  LockOutlined,
  PictureOutlined,
  SafetyOutlined,
  StopOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../utils";
import { startLoading, stopLoading } from "../../redux/slice/globalLoaderSlice";
import PasswordUpdate from "./password-update";
import DeleteAccount from "./delete-account";
import ConfirmPopup from "../../custom/confirm-popup";
import { MODAL_TYPES } from "../../constant";
import { handleProfileImage } from "../../helper";
import "./style.scss";

const { Title } = Typography;

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();
  const [accountStatus, setAccountStatus] = useState({});
  const [modalType, setModalType] = useState(null);
  const [confirmDetails, setConfirmDetails] = useState({
    type: null,
    title: null,
    payload: {},
  });

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { account_status, account_delete_status } = accountStatus;

  const handleMyProfile = () =>
    window.open(`/search/${userDetails.user_id}`, "_blank");

  const handleNavigate = (path) => navigate(path);

  const handleModal = (type = null) => setModalType(type);

  const handleProfileStatus = () => {
    const payload = {
      account_status: account_status ? "deactivate" : "activate",
    };
    handleSubmit(MODAL_TYPES.ACCOUNT_STATUS, payload);
  };

  const handleDelete = () => {
    const type = MODAL_TYPES.DELETE_PROFILE;
    account_delete_status ? handleSubmit(type, {}) : handleModal(type);
  };

  const profileMenuList = [
    {
      title: "My Profile",
      icon: <UserOutlined />,
      action: handleMyProfile,
    },
    {
      title: "My Photos",
      icon: <PictureOutlined />,
      action: () => handleNavigate("/myphotos"),
    },
    {
      title: "Change Password",
      icon: <LockOutlined />,
      action: () => handleModal(MODAL_TYPES.CHANGE_PASSWORD),
    },
    {
      title: "Profile History",
      icon: <HistoryOutlined />,
      action: () => handleNavigate("/profile-history"),
    },
    {
      title: account_status ? "Deactivate Profile" : "Activate Profile",
      icon: account_status ? <StopOutlined /> : <SafetyOutlined />,
      action: handleProfileStatus,
    },
    {
      title: account_delete_status ? "Cancel Profile Delete" : "Delete Profile",
      icon: <UserDeleteOutlined />,
      action: handleDelete,
    },
  ];

  const handleConfirmCancel = () =>
    setConfirmDetails({ type: null, title: null, payload: {} });

  const handleAccountStatus = (responseData) => {
    const newAccountStatus = { ...accountStatus, ...responseData.data };
    localStorage.setItem("account_status", JSON.stringify(newAccountStatus));
    setAccountStatus(newAccountStatus);
  };

  const handleSubmit = (type, payload) => {
    const titleMap = {
      [MODAL_TYPES.ACCOUNT_STATUS]: `Are you sure you want to ${
        account_status ? "deactivate" : "activate"
      } your profile?`,
      [MODAL_TYPES.CHANGE_PASSWORD]:
        "Are you sure you want to change your profile password?",
      [MODAL_TYPES.DELETE_PROFILE]: account_delete_status ? (
        "Are you sure you want to cancel your profile delete request?"
      ) : (
        <div>
          Are you sure you want to delete your profile?
          <br />
          Please note that all your profile details will be permanently deleted
          if the request is approved by the admin.
          <br />
          Once deleted, your profile cannot be recovered.
        </div>
      ),
    };

    setConfirmDetails({
      type,
      title: titleMap[type],
      payload,
    });
  };

  const handleConfirm = async ({ type, payload }) => {
    handleConfirmCancel();
    dispatch(startLoading());
    try {
      const MODAL_CONFIG = {
        [MODAL_TYPES.CHANGE_PASSWORD]: {
          method: "post",
          url: "change_password",
          success: () => handleModal(),
        },
        [MODAL_TYPES.ACCOUNT_STATUS]: {
          method: "post",
          url: "set_user_account_status",
          success: handleAccountStatus,
        },
        [MODAL_TYPES.DELETE_PROFILE]: {
          method: account_delete_status ? "post" : "delete",
          url: account_delete_status
            ? "cancel_profile_delete_request"
            : "request_profile_delete",
          success: () => handleNavigate("/login"),
        },
      };

      const { method, url, success } = MODAL_CONFIG[type];
      const response = await authAxios({
        method,
        url,
        data: payload,
      });
      success(response.data);
      notification.success({ message: response.data.message });
    } catch (error) {
      notification.error({
        message:
          error?.response?.data?.message ||
          "Update failed. Please try agian later",
      });
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    setAccountStatus(JSON.parse(localStorage.getItem("account_status")));
  }, []);

  return (
    <>
      <Flex
        align="center"
        className="profile-settings-container"
        gap={24}
        vertical
      >
        <Image
          alt="profile-pic"
          src={handleProfileImage(userDetails)}
          preview={false}
          className="profile-photo"
        />
        <Title align="center" level={3}>
          {userDetails.user_name || ""} ({userDetails.user_id || ""})
        </Title>
        <Flex gap={16} justify="center" align="center" wrap>
          {profileMenuList.map(({ icon, title, action }) => (
            <Card className="menu-card" hoverable key={title} onClick={action}>
              <Flex align="center" gap={8} justify="center" vertical>
                {icon}
                <span className="menu-title">{title}</span>
              </Flex>
            </Card>
          ))}
        </Flex>
      </Flex>
      {modalType && (
        <Modal
          centered
          closeIcon={false}
          open={modalType}
          onCancel={() => handleModal()}
          width="450px"
          footer={null}
        >
          {modalType === MODAL_TYPES.CHANGE_PASSWORD && (
            <PasswordUpdate
              handleModal={() => handleModal()}
              handleSubmit={handleSubmit}
            />
          )}
          {modalType === MODAL_TYPES.DELETE_PROFILE && (
            <DeleteAccount
              handleModal={() => handleModal()}
              handleSubmit={handleSubmit}
            />
          )}
        </Modal>
      )}
      {confirmDetails.type && (
        <ConfirmPopup
          onOk={() => handleConfirm(confirmDetails)}
          onCancel={handleConfirmCancel}
          open={confirmDetails.type}
          title={confirmDetails.title}
        />
      )}
    </>
  );
};

export default Profile;
