import { Layout } from "antd";
import Header from "../../views/header";
// import GlobalLoader from "../../components/global-loader";
import { Outlet } from "react-router-dom";
import "../layout-style.scss";

const { Header: HeaderLayout, Content } = Layout;

const LoginLayout = () => {
  return (
    <Layout className="layout-container">
      <HeaderLayout>
        <Header />
      </HeaderLayout>
      <Content>
        {/* <GlobalLoader /> */}
        <Outlet />
      </Content>
    </Layout>
  );
};

export default LoginLayout;
