import { Button, Flex, Form, Typography } from "antd";
import {
  ContactDetailsForm,
  EducationOccupationDetailsForm,
  HoroscopeDetailsForm,
} from "../../components";

const { Title } = Typography;

const Step2Form = (properties) => {
  const { formData, handleFormData, isLoading, setIsLoading } = properties;
  const [form] = Form.useForm();

  const handlePrevious = () => {
    const fieldsValue = form.getFieldsValue();
    handleFormData(fieldsValue, 0);
  };

  const onFinish = (formValues) => {
    setIsLoading(true);
    handleFormData(formValues, 2);
  };

  return (
    <Form
      labelWrap
      form={form}
      autoComplete="off"
      scrollToFirstError
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      initialValues={formData}
      wrapperCol={{ span: 14 }}
    >
      <Title level={4}>Horoscope Details</Title>
      <HoroscopeDetailsForm />
      <Title level={4}>Education & Occupation Details</Title>
      <EducationOccupationDetailsForm />
      <Title level={4}>Contact Details</Title>
      <ContactDetailsForm />
      <Flex justify="center" gap="small">
        <Button onClick={handlePrevious}>Previous</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Continue
        </Button>
      </Flex>
    </Form>
  );
};

export default Step2Form;
