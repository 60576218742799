import { Input } from "antd";
import "./style.scss";

const NumericInput = (properties) => {
  // Its allows only numeric value
  const handleNumericKey = (event) => {
    const isNonNumericKey = /^\D$/.test(event.key);
    const isControlKey = event.ctrlKey;
    if (isNonNumericKey && !isControlKey) {
      event.preventDefault();
    }
  };

  return <Input onKeyDown={handleNumericKey} {...properties} />;
};

export default NumericInput;
