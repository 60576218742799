import React, { useState } from "react";
import { Button, Flex, Form, Modal, notification } from "antd";
import {
  BasicDetailsForm,
  ContactDetailsForm,
  EducationOccupationDetailsForm,
  FamilyDetailsForm,
  HoroscopeDetailsForm,
  PartnerPreferenceDetailsForm,
  PhysicalDetailsForm,
} from "../../components";
import CustomButton from "../../custom/success-button";
import { handleArrayEqual, handleObjectDifference } from "../../utils/helper";
import { UseAuthAxios } from "../../utils";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/slice/globalLoaderSlice";

const editDetails = (form) => ({
  basic_details: {
    component: <BasicDetailsForm isEdit={true} />,
  },
  contact_details: {
    component: <ContactDetailsForm />,
  },
  education_details: {
    component: <EducationOccupationDetailsForm />,
  },
  family_details: {
    component: <FamilyDetailsForm form={form} isEdit={true} />,
  },
  horoscope_details: { component: <HoroscopeDetailsForm isEdit={true} /> },
  partner_preference: {
    component: <PartnerPreferenceDetailsForm />,
  },
  physical_details: {
    component: <PhysicalDetailsForm form={form} isEdit={true} />,
  },
});

const ProfileEditModal = (properties) => {
  const { modalDetails, profileData, handleModal, handleProfile } = properties;
  const { isOpen, key, title } = modalDetails;

  const authAxios = UseAuthAxios();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(true);

  const handleCancel = () => handleModal();

  const onValuesChange = (values) => {
    const [[updatedKey, updatedValue]] = Object.entries(values[key]);
    const oldValue = profileData[key][updatedKey];
    if (Array.isArray(updatedValue)) {
      return setIsUpdate(handleArrayEqual(oldValue, updatedValue));
    }
    setIsUpdate(updatedValue === oldValue);
  };

  const handleEditUpdate = async (payload) => {
    try {
      const { data } = await authAxios({
        method: "put",
        url: "/edit_profile",
        data: payload,
      });
      handleProfile(data.data);
      notification.success({
        message: data.message || "Details update failed. Try again later.",
      });
    } catch (error) {
      notification.error({
        message:
          error?.response?.data?.message ||
          "Details update failed. Try again later.",
      });
    } finally {
      handleCancel();
      dispatch(stopLoading());
    }
  };

  const onFinish = ({ [key]: formValues }) => {
    dispatch(startLoading());
    const edit_data = handleObjectDifference(profileData[key], formValues);
    const { profile_id } = profileData?.basic_details;
    const payload = {
      profile_id,
      edit_data,
      edit_feature:
        key === "partner_preference" ? "partner_preference_details" : key,
    };
    handleEditUpdate(payload);
  };

  return (
    <Modal
      centered
      title={title}
      open={isOpen}
      maskClosable={false}
      footer={null}
      onCancel={handleCancel}
      className="profile-edit-modal"
    >
      <Form
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        layout="vertical"
        initialValues={profileData}
        onValuesChange={onValuesChange}
      >
        {editDetails(form)[key].component}
        <Flex justify="center" gap="small">
          <CustomButton
            success
            type="primary"
            htmlType="submit"
            disabled={isUpdate}
          >
            Update
          </CustomButton>
          <Button danger type="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default ProfileEditModal;
