import { message, Upload } from "antd";

export const handleFileValidation = ({ type, size }) => {
  const isValidType = ["image/jpeg", "image/jpg", "image/png"].includes(type);

  if (!isValidType) {
    message.error("You can only upload JPEG/JPG/PNG files");
    return Upload.LIST_IGNORE;
  }

  if (size >= 5 * 1024 * 1024) {
    message.error("Photo size must be smaller than 5MB");
    return Upload.LIST_IGNORE;
  }

  return false;
};

export const handleArrayEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }
  const array1Set = new Set(array1);
  return !array2.some((item) => !array1Set.has(item));
};

export const handleObjectDifference = (object1, object2) => {
  const modifiedData = {};

  for (const key in object2) {
    const value1 = object1[key];
    const value2 = object2[key];
    const isArray = Array.isArray(value2);
    if (isArray && !handleArrayEqual(value1, value2)) {
      modifiedData[key] = value2;
    }
    if (!isArray && value2 !== value1) {
      modifiedData[key] = value2;
    }
  }

  return modifiedData;
};
