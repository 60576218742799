import { useEffect, useState } from "react";
import { Button, Card, Flex, Timeline } from "antd";
import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CrownOutlined,
  FileExcelOutlined,
  FileProtectOutlined,
  FormOutlined,
  LockOutlined,
  RedoOutlined,
  SafetyOutlined,
  StopOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import useAuthAxios from "../../utils/useAuthAxios";
import { startLoading, stopLoading } from "../../redux/slice/globalLoaderSlice";
import Notification from "../../components/notification";
import "./style.scss";

const profileEvents = {
  "Profile Created": {
    icon: <UserOutlined />,
    className: "success",
  },
  "Profile Verification - Success": {
    icon: <FileProtectOutlined />,
    className: "success",
  },
  "Profile Verification - Rejected": {
    icon: <FileExcelOutlined />,
    className: "error",
  },
  "Profile Activated": {
    icon: <SafetyOutlined />,
    className: "success",
  },
  "Profile Deactivated": {
    icon: <StopOutlined />,
    className: "error",
  },
  "Profile Delete - Requested": {
    icon: <UserDeleteOutlined />,
    className: "warning",
  },
  "Profile Delete - Canceled": {
    icon: <CloseOutlined />,
    className: "error",
  },
  "Profile Delete - Rejected": {
    icon: <CloseCircleOutlined />,
    className: "error",
  },
  "Profile Membership Updated": {
    icon: <CrownOutlined />,
    className: "upgrade",
  },
  "Profile Edited": {
    icon: <FormOutlined />,
    className: "edited",
  },
  "Profile Password Changed": {
    icon: <LockOutlined />,
    className: "warning",
  },
  "Profile Password Reset": {
    icon: <RedoOutlined />,
    className: "warning",
  },
};

const ProfileHistory = () => {
  const authAxios = useAuthAxios();
  const dispatch = useDispatch();

  const [historyItems, setHistoryItems] = useState([]);
  const [isReverse, setIsReverse] = useState(false);

  const handleHistoryItems = (data) => {
    const items = data.map(({ action, created_at, notes }) => ({
      dot: profileEvents[action]?.icon,
      label: <b>{created_at}</b>,
      children: (
        <Flex vertical>
          <b>{action}</b>
          {notes && <span className="content">{notes}</span>}
        </Flex>
      ),
      className: profileEvents[action]?.className,
    }));
    setHistoryItems(items);
  };

  const handleFetchHistory = async () => {
    try {
      dispatch(startLoading());
      const { data } = await authAxios("/get_profile_history");
      handleHistoryItems(data?.data?.results || []);
    } catch (error) {
      Notification(
        "error",
        error?.response?.data?.message ||
          "Failed to get history. Please try again later!"
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    handleFetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      className="history-timeline-container"
      title="Profile History"
      extra={
        <Button
          disabled={!historyItems.length}
          onClick={() => setIsReverse(!isReverse)}
          type="primary"
        >
          {
            <Flex gap={8}>
              {isReverse ? "New" : "Old"}
              <ArrowRightOutlined />
              {isReverse ? "Old" : "New"}
            </Flex>
          }
        </Button>
      }
    >
      {historyItems.length ? (
        <Timeline items={historyItems} reverse={isReverse} mode="left" />
      ) : (
        <Flex justify="center">
          <span className="content">No History</span>
        </Flex>
      )}
    </Card>
  );
};

export default ProfileHistory;
