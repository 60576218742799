import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Empty, Flex, Image, Row, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ProfileDetails from "./profile-details";
import { UseAuthAxios } from "../../utils";
import { startLoading, stopLoading } from "../../redux/slice/globalLoaderSlice";
import Advertisement from "./advertisement";
import ActionButton from "./action-button";
import { handleProfileImage } from "../../helper";
import "./style.scss";

const SearchById = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();
  const globalLoading = useSelector((state) => state.globalLoader.isLoading);

  const { is_admin_user } = JSON.parse(localStorage.getItem("account_status"));

  const [errorMessage, setErrorMessage] = useState(null);
  const [profileData, setProfileData] = useState({});

  const { basic_details, other_details } = profileData;

  const handleProfile = (data) => setProfileData(data);

  const handleProfileSection = (key, value) =>
    setProfileData((previous) => ({
      ...previous,
      [key]: {
        ...previous[key],
        ...value,
      },
    }));

  const handleConnectionStatus = (request_status) => {
    dispatch(startLoading());
    const method = "post";
    const url = "/respond_connection_request";
    const statusPayload = {
      accept_reject_connection_id: other_details.accept_reject_connection_id,
      request_status,
      target_profile_id: basic_details.profile_id,
    };
    authAxios({
      method,
      url,
      data: statusPayload,
    })
      .then(() => {
        handleProfileSection("other_details", {
          profile_connection_status: request_status,
          is_show_accept_reject_button: false,
        });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  useEffect(() => {
    dispatch(startLoading());
    const profileUrl = `/search_profile_by_id/${id}`;
    authAxios
      .get(profileUrl)
      .then((response) => {
        setProfileData(response.data.data);
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(error.response.data.message);
          notification.error({ message: error.response.data.message });
        }
      })
      .finally(() => dispatch(stopLoading()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      {profileData.basic_details ? (
        <>
          <Advertisement />
          <Row
            justify="center"
            gutter={[0, 16]}
            className="profile-pic-container"
          >
            <Col span={24} align="middle">
              <Image.PreviewGroup items={other_details.profile_photo_list}>
                <Image
                  src={handleProfileImage(profileData, true)}
                  preview={!!other_details.profile_photo_list.length}
                />
              </Image.PreviewGroup>
            </Col>
            {!other_details.is_hide_action_buttons && (
              <ActionButton
                authAxios={authAxios}
                dispatch={dispatch}
                profileData={profileData}
                handleProfile={handleProfile}
                handleProfileSection={handleProfileSection}
              />
            )}
            {other_details.is_show_accept_reject_button && (
              <Flex gap="small">
                <Button
                  onClick={() => handleConnectionStatus("Accepted")}
                  type="primary"
                >
                  Accept
                </Button>
                <Button
                  danger
                  onClick={() => handleConnectionStatus("Rejected")}
                  type="primary"
                >
                  Reject
                </Button>
              </Flex>
            )}
            <Col span={24} align="middle">
              <h3 className="profile-title">
                {profileData.basic_details.name} (
                {profileData.basic_details.profile_id})
              </h3>
            </Col>
            {/* <Col className="view-count-container">
              {other_details.visited_profiles_count} user viewed this profile
            </Col> */}
            <Col align="middle">
              {other_details.last_active_datetime ? (
                <span className="last-active">
                  Last Active at: {other_details.last_active_datetime}
                  <br />
                </span>
              ) : null}

              {is_admin_user && (
                <>
                  <span>Account Status: {other_details.account_status}</span>
                  <br />
                  <span>
                    Profile Delete: {other_details.delete_profile_status}
                  </span>
                </>
              )}
            </Col>
          </Row>
          <ProfileDetails
            profileData={profileData}
            handleProfile={handleProfile}
          />
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            globalLoading
              ? "Loading data, Please wait..."
              : `No profile match. ${errorMessage}`
          }
        />
      )}
    </Card>
  );
};

export default SearchById;
