import { Flex, Table, Typography } from "antd";
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";
import "./style.scss";

const columns = [
  {
    dataIndex: "feature",
    key: "feature",
    title: "FEATURE",
    fixed: "left",
  },
  {
    dataIndex: "BASIC",
    key: "BASIC",
    title: "BASIC",
  },
  {
    dataIndex: "GOLD",
    key: "GOLD",
    title: "GOLD",
  },
  {
    dataIndex: "DIAMOND",
    key: "DIAMOND",
    title: "DIAMOND",
  },
  {
    dataIndex: "PLATINUM",
    key: "PLATINUM",
    title: "PLATINUM",
  },
];

const dataSource = [
  {
    BASIC: "18 Months (Profile Auto Delete)",
    DIAMOND: "6 Months (180 Days)",
    GOLD: "3 Months (90 Days)",
    PLATINUM: "12 Months (365 Days)",
    feature: "Membership Validity",
    key: "Membership Validity",
  },
  {
    BASIC: "5",
    DIAMOND: "50",
    GOLD: "30",
    PLATINUM: "Unlimited",
    feature: "Max Profile Shortlist",
    key: "Max Profile Shortlist",
  },
  {
    BASIC: "3",
    DIAMOND: "5",
    GOLD: "5",
    PLATINUM: "5",
    feature: "Max Photo Upload",
    key: "Max Photo Upload",
  },
  {
    BASIC: <CloseOutlined />,
    DIAMOND: <CheckCircleFilled />,
    GOLD: <CheckCircleFilled />,
    PLATINUM: <CheckCircleFilled />,
    feature: "Full Profile View",
    key: "Full Profile View",
  },
  {
    BASIC: "0",
    DIAMOND: "50",
    GOLD: "30",
    PLATINUM: "Unlimited",
    feature: "Contact Number Views",
    key: "Contact Number Views",
  },
  {
    BASIC: "Only last 5 profiles",
    DIAMOND: "Unlimited",
    GOLD: "Unlimited",
    PLATINUM: "Unlimited",
    feature: "Profile Viewers List",
    key: "Profile Viewers List",
  },
  {
    BASIC: <span className="price-container">Free</span>,
    DIAMOND: <span className="price-container">****₹</span>,
    GOLD: <span className="price-container">****₹</span>,
    PLATINUM: <span className="price-container">****₹</span>,
    feature: "Price",
    key: "Price",
  },
];

const Payment = () => {
  return (
    <Flex gap={24} vertical>
      <Table
        bordered
        className="payment-table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{
          x: 768,
        }}
      />
      <Typography.Title level={5} type="danger">
        *Note : Membership features and payment details will vary time to time.
      </Typography.Title>
    </Flex>
  );
};

export default Payment;
