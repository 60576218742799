import { configureStore } from "@reduxjs/toolkit";
import globalLoaderSlice from "../slice/globalLoaderSlice";

const store = configureStore({
  reducer: {
    globalLoader: globalLoaderSlice,
  },
});

export default store;
