import { useEffect, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Flex,
  Form,
  Select,
  Statistic,
  Table,
} from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UseAuthAxios } from "../../../utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/slice/globalLoaderSlice";
import CHOICES from "../../../constant/register-choices.json";
import { handleDateFormat } from "../../../helper";
import "./style.scss";

const { RangePicker } = DatePicker;

const { GENDER_CHOICES } = CHOICES;

const dateFormat = "YYYY/MM/DD";

const MEMBERSHIP_OPTIONS = [
  { label: "BASIC", value: "BASIC" },
  { label: "GOLD", value: "GOLD" },
  { label: "DIAMOND", value: "DIAMOND" },
  { label: "PLATINUM", value: "PLATINUM" },
];

const columns = [
  {
    title: "GENDER",
    dataIndex: "gender",
    fixed: "left",
  },
  {
    title: "BASIC",
    dataIndex: "basic",
  },
  {
    title: "GOLD",
    dataIndex: "gold",
  },
  {
    title: "DIAMOND",
    dataIndex: "diamond",
  },
  {
    title: "PLATINUM",
    dataIndex: "platinum",
  },
  {
    title: "TOTAL",
    dataIndex: "total",
  },
];

const tableDataConfig = [
  { gender: "MALE", key: "male" },
  { gender: "FEMALE", key: "female" },
  { gender: "TRANSGENDER", key: "transgender" },
  { gender: "TOTAL", key: "total", isTotal: true },
];

const profileStatsConfig = [
  {
    title: "Pending Profile Verification",
    key: "pending_profile_verification",
    path: "/admin/profile-verification",
  },
  {
    title: "Pending Profile Delete",
    key: "pending_profile_delete",
    path: "/admin/delete-profile",
  },
  {
    title: "Pending Reported Issues",
    key: "pending_issue_reports",
    path: "/admin/report/reported-issues",
  },
  {
    title: "Pending Reported Profile",
    key: "pending_profile_reports",
    path: "/admin/report/reported-account",
  },
  {
    title: "Today Login Count",
    key: "today_login_count",
  },
];

const overallProfileCountConfig = [
  { label: "Overall Total Profile Count", value: "total_profile_count" },
  { label: "Overall Total Male Profile Count", value: "male_profile_count" },
  {
    label: "Overall Total Female Profile Count",
    value: "female_profile_count",
  },
  {
    label: "Overall Total Transgender Profile Count",
    value: "transgender_profile_count",
  },
];

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();
  const [form] = Form.useForm();
  const [dashboardData, setDashboardData] = useState({});
  const { main_table_data = {}, overall_profile_table_data = {} } =
    dashboardData;

  const handleFetchDashboard = async (payload = {}) => {
    try {
      dispatch(startLoading());
      const { data } = await authAxios.post("/admin_dashboard", payload);
      setDashboardData(data.data);
    } catch (error) {
      Notification(
        error.response.data.message ||
          "Failed to fetch dashboard data. Please try again later!"
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleFinish = ({
    dateRange = [],
    membership_type = [],
    gender = [],
  }) => {
    const [from_date, to_date] = dateRange?.map((date) =>
      handleDateFormat(date, dateFormat)
    ) || ["", ""];

    const payload = { from_date, to_date, membership_type, gender };

    handleFetchDashboard(payload);
  };

  const tableData = tableDataConfig.map(({ gender, key, isTotal = false }) => {
    const prefix = isTotal ? "" : `${key}_`;
    return {
      gender,
      basic: main_table_data?.[`${prefix}basic_membership_count`],
      gold: main_table_data?.[`${prefix}gold_membership_count`],
      diamond: main_table_data?.[`${prefix}diamond_membership_count`],
      platinum: main_table_data?.[`${prefix}platinum_membership_count`],
      total: main_table_data?.[`${key}_profile_count`],
    };
  });

  const handleReset = () => {
    form.resetFields();
    handleFetchDashboard();
  };

  useEffect(() => {
    handleFetchDashboard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-dashboard-container">
      <Form form={form} onFinish={handleFinish}>
        <Flex justify="center" gap={24} wrap>
          <Form.Item label="From Date - To Date" name="dateRange">
            <RangePicker />
          </Form.Item>
          <Form.Item label="MemberShip Type" name="membership_type">
            <Select
              mode="multiple"
              placeholder="Select type"
              options={MEMBERSHIP_OPTIONS}
            />
          </Form.Item>
          <Form.Item label="Gender" name="gender">
            <Select
              mode="multiple"
              placeholder="Select gender"
              options={GENDER_CHOICES}
            />
          </Form.Item>
          <Flex justify="center" gap={8}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button danger type="primary" onClick={handleReset}>
              Reset
            </Button>
          </Flex>
        </Flex>
      </Form>
      <Flex justify="center" gap={16} wrap>
        {profileStatsConfig.map(({ title, key, path }) => (
          <Card hoverable key={key} onClick={() => navigate(path)}>
            <Statistic title={title} value={dashboardData[key]} />
          </Card>
        ))}
      </Flex>
      <Divider />
      <Table
        bordered
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ x: 768 }}
      />
      <Divider />
      <Flex align="center" vertical gap={16} className="overall-container">
        {overallProfileCountConfig.map(({ label, value }) => (
          <span key={value}>
            {label} - {overall_profile_table_data?.[value]}
          </span>
        ))}
      </Flex>
      <Divider />
    </div>
  );
};

export default AdminDashboard;
