import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Image,
  Input,
  List,
  Pagination,
  Row,
  Typography,
  notification,
} from "antd";
import { HeartFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../utils";
import { handleProfileImage } from "../../helper";
import SearchFilterForm from "./search-filter-form";
import { startLoading, stopLoading } from "../../redux/slice/globalLoaderSlice";
import "./style.scss";

const Search = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [searchData, setSearchData] = useState([]);
  const [pageData, setPageData] = useState(null);

  const handleSessionExpired = () => {
    alert("Your session has expired. Please log in again.");
    navigate("/login");
  };

  const handleSearchByID = ({ profile_id }) => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      form1.resetFields();
      window.open(`/search/${profile_id}`, "_blank");
    } else {
      handleSessionExpired();
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  const handleSearchByFilters = (
    payload,
    currentPage = 1,
    newPageSize = 10
  ) => {
    const url = "search_profile";
    payload.page_number = currentPage;
    payload.records_per_page = newPageSize;
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      dispatch(startLoading());
      authAxios
        .post(url, payload)
        .then((response) => {
          const { data, pagination_data } = response.data;
          setSearchData(data);
          setPageData(pagination_data);
          handleScrollToTop();
          if (!data.length) {
            notification.error({ message: "No matches found" });
          }
        })
        .catch((error) =>
          notification.error({ message: error.response.data.message })
        )
        .finally(() => dispatch(stopLoading()));
    } else {
      handleSessionExpired();
    }
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    form1.setFieldsValue({ profile_id: value.toUpperCase() });
  };

  const onPageChange = (currentPage, newPageSize) => {
    const payload = form2.getFieldsValue();
    handleSearchByFilters(payload, currentPage, newPageSize);
  };

  useEffect(() => {
    const payload = form2.getFieldsValue();
    handleSearchByFilters(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form
        form={form1}
        autoComplete="off"
        className="search-id-form"
        onFinish={handleSearchByID}
      >
        <Row align="middle" justify="center" gutter={[16, 16]}>
          <Col xs={24} sm={24} md={16} lg={12} xl={10} xxl={8}>
            <Form.Item
              name="profile_id"
              label="Search Profile ID"
              rules={[
                {
                  required: true,
                  message: "Please enter profile ID",
                },
              ]}
            >
              <Input
                onChange={handleInputChange}
                placeholder="Enter Profile ID"
              />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider>or</Divider>
      <SearchFilterForm
        form2={form2}
        handleSearchByFilters={handleSearchByFilters}
      />
      <Divider style={{ borderTop: "2px solid #8b8b8b" }} />
      <List
        itemLayout="vertical"
        dataSource={searchData}
        renderItem={(item) => {
          const items = [
            {
              key: "age",
              label: "Age",
              children: item.basic_details.age,
            },
            {
              key: "gender",
              label: "Gender",
              children: item.basic_details.gender,
            },
            {
              key: "height_in_cm",
              label: "Height",
              children: `${item.physical_details.height_in_cm} cm /
                ${item.physical_details.height_in_inch}`,
            },
            {
              key: "weight_in_kg",
              label: "Weight",
              children: item.physical_details.weight_in_kg,
            },
            {
              key: "rasi",
              label: "Rasi",
              children: item.horoscope_details.rasi,
            },
            {
              key: "nachathiram",
              label: "Nachathiram",
              children: item.horoscope_details.star,
            },
            {
              key: "is_sevvai",
              label: "Sevvai",
              children: item.horoscope_details.is_sevvai,
            },
            {
              key: "is_ragu_kedhu",
              label: "Ragu Kedhu",
              children: item.horoscope_details.is_ragu_kedhu,
            },
            {
              key: "kulam",
              label: "Kulam",
              children: item.basic_details.kulam,
            },
            {
              key: "education",
              label: "Education",
              children: item?.education_details?.education?.join(", "),
            },
            {
              key: "location",
              label: "Location",
              children: item.basic_details.location,
            },
            {
              key: "marital_status",
              label: "Marital Status",
              children: item.basic_details.marital_status,
            },
          ];

          return (
            <>
              <Card className="search-container">
                <Row gutter={[16, 16]} align="middle">
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={6}
                    xl={3}
                    className="divider"
                  >
                    <Row gutter={[0, 16]} justify="center">
                      <Col span={24} align="center">
                        <Image preview={false} src={handleProfileImage(item)} />
                      </Col>
                      <Col>
                        <Button
                          type="primary"
                          onClick={() => handleSearchByID(item.basic_details)}
                        >
                          <Typography.Text ellipsis>
                            View Profile
                          </Typography.Text>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} lg={18} xl={21} xxl={21}>
                    <Descriptions
                      bordered
                      items={items}
                      className="profile-card"
                      column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 3 }}
                      title={
                        <>
                          {item.basic_details.name}{" "}
                          {item.basic_details.profile_id}{" "}
                          {item.other_details.is_favourite_profile && (
                            <HeartFilled className="fav-btn" />
                          )}
                        </>
                      }
                    />
                  </Col>
                </Row>
              </Card>
              <br />
            </>
          );
        }}
      />
      {searchData.length ? (
        <Pagination
          className="filter-pagination"
          showSizeChanger
          onChange={onPageChange}
          current={pageData?.requested_page_number}
          pageSize={pageData?.requested_records_per_page}
          pageSizeOptions={[10, 20, 30]}
          total={pageData?.total_objects}
        />
      ) : null}
    </>
  );
};

export default Search;
