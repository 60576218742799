import { useEffect, useState } from "react";
import {
  Table,
  Button,
  notification,
  Pagination,
  Typography,
  Form,
  Col,
  Select,
  Row,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/slice/globalLoaderSlice";
import "./style.scss";

const { Option } = Select;

const getRefreshToken = () => localStorage.getItem("refreshToken");

const InactiveProfiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();

  const [form1] = Form.useForm();
  const [inActiveData, setInActiveData] = useState([]);
  const [pagination, setPagination] = useState({});

  const inactive_duration_options = [30, 60, 90, 120, 180, 365];
  const initialValues = {
    inactive_duration_in_days: 30,
    page_number: 1,
    records_per_page: 10,
  };

  const columns = [
    {
      title: "Profile ID",
      dataIndex: "profile_id",
    },
    {
      title: "Profile Name",
      dataIndex: "profile_name",
    },
    {
      title: "Membership Type",
      dataIndex: "membership_type",
    },
    {
      title: "Registered At",
      dataIndex: "registered_at",
    },
    {
      title: "last login at",
      dataIndex: "last_login_at",
    },
    {
      title: "Duration in Days",
      dataIndex: "duration_in_days",
    },
    {
      title: "Action",
      render: (data) => (
        <Button
          type="primary"
          onClick={() => {
            handleViewProfile(data?.profile_id);
          }}
        >
          View Profile
        </Button>
      ),
    },
  ];

  const fetchInactiveProfiles = (payload) => {
    dispatch(startLoading());
    const url = "/list_inactive_profiles";

    authAxios
      .post(url, payload)
      .then((response) => {
        setInActiveData(response.data.data.results);
        setPagination(response.data.data.pagination_data);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleSessionExpired = () => {
    alert("Your session has expired. Please log in again.");
    navigate("/login");
  };

  const handleViewProfile = (profile_id) => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      window.open(`/search/${profile_id}`, "_blank");
    } else {
      handleSessionExpired();
    }
  };

  const handlePageChange = (pageNo) => {
    const { inactive_duration_in_days } = form1.getFieldsValue();
    const payload = {
      inactive_duration_in_days,
      page_number: pageNo,
      records_per_page: 10,
    };
    fetchInactiveProfiles(payload);
  };

  const onFinishSearch = ({ inactive_duration_in_days }) => {
    const payload = {
      inactive_duration_in_days,
      page_number: 1,
      records_per_page: 10,
    };
    fetchInactiveProfiles(payload);
  };

  useEffect(() => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      fetchInactiveProfiles(initialValues);
    } else {
      handleSessionExpired();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography.Title level={3} className="page-title">
        Inactive Profiles
      </Typography.Title>
      <Form
        autoComplete="off"
        className="profile-verification-search"
        initialValues={initialValues}
        form={form1}
        onFinish={onFinishSearch}
      >
        <Row align="middle" gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item
              label="Inactive Duration (days)"
              name="inactive_duration_in_days"
            >
              <Select placeholder="Select profile verification status">
                {inactive_duration_options.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        columns={columns}
        dataSource={inActiveData}
        pagination={false}
        bordered
        scroll={{
          x: 920,
        }}
        rowKey="profile_id"
      />
      <br />
      <Pagination
        total={pagination.total_objects}
        current={pagination.requested_page_number}
        onChange={handlePageChange}
      />
    </>
  );
};

export default InactiveProfiles;
