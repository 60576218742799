import React from "react";
import { Form, Input, Radio, Select, Slider } from "antd";
import CHOICES from "../constant/register-choices.json";

const { Option, OptGroup } = Select;
const { Group } = Radio;
const { TextArea } = Input;

const {
  DIET_CHOICES,
  EDUCATION_LIST,
  MARITAL_STATUS_CHOICES,
  OCCUPATION_CATEGORY,
  OCCUPATION_LIST,
  PREFERENCE_CHOICES,
  SKIN_COLOR_CHOICES,
} = CHOICES;

const PartnerPreferenceDetailsForm = () => {
  return (
    <>
      <Form.Item
        label="Marital Status"
        name={["partner_preference", "marital_status"]}
        rules={[
          { required: true, message: "Please select your marital status" },
        ]}
      >
        <Select placeholder="Select your marital status">
          {MARITAL_STATUS_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Age Range" name={["partner_preference", "age_range"]}>
        <Slider range min={18} max={45} />
      </Form.Item>
      <Form.Item label="Skin Tone" name={["partner_preference", "skin_tone"]}>
        <Group>
          {SKIN_COLOR_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item label="Education" name={["partner_preference", "education"]}>
        <Select showSearch mode="multiple" placeholder="Select your education">
          {EDUCATION_LIST.map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Occupation" name={["partner_preference", "occupation"]}>
        <Select showSearch placeholder="Select your occupation">
          {OCCUPATION_CATEGORY?.map((group) => (
            <OptGroup key={group} label={group}>
              {OCCUPATION_LIST[group].map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Horoscope Match"
        name={["partner_preference", "is_horoscope_match"]}
      >
        <Group>
          {PREFERENCE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item label="Sevvai" name={["partner_preference", "is_sevvai"]}>
        <Group>
          {PREFERENCE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="Raaghu/Kedhu"
        name={["partner_preference", "is_ragu_kethu"]}
      >
        <Group>
          {PREFERENCE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item label="Brothers" name={["partner_preference", "is_brother"]}>
        <Group>
          {PREFERENCE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item label="Sisters" name={["partner_preference", "is_sisters"]}>
        <Group>
          {PREFERENCE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="Food Preference"
        name={["partner_preference", "food_preference"]}
      >
        <Group>
          {DIET_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="What I'm Looking For"
        name={["partner_preference", "description"]}
        rules={[
          {
            pattern: /^\D*$/,
            message: "Please enter only albhabets",
          },
        ]}
      >
        <TextArea
          maxLength={250}
          count={{
            show: true,
          }}
          rows={3}
          placeholder="Write your expectations"
        />
      </Form.Item>
    </>
  );
};

export default PartnerPreferenceDetailsForm;
