import { Button, Flex, Form, Input } from "antd";
import { encryptData } from "../../utils";
import { MODAL_TYPES } from "../../constant";

const PasswordUpdate = ({ handleModal, handleSubmit }) => {
  const [form] = Form.useForm();

  const handleValidPassword = (data, value) => {
    const passwordFields = {
      new_password: {
        fieldName: "old_password",
        message: "New password must be different from the old password",
        isInvalid: (value, compareValue) => value === compareValue,
      },
      confirm_new_password: {
        fieldName: "new_password",
        message: "Confirmation password must match the new password",
        isInvalid: (value, compareValue) => value !== compareValue,
      },
    };

    const { fieldName, message, isInvalid } = passwordFields[data.field];
    const compareValue = form.getFieldValue(fieldName);

    if (value && isInvalid(value, compareValue)) {
      return Promise.reject(new Error(message));
    }

    return Promise.resolve();
  };

  const onFinish = ({ old_password, new_password }) => {
    const newPassword = encryptData(new_password);

    const payload = {
      confirm_new_password: newPassword,
      new_password: newPassword,
      old_password: encryptData(old_password),
    };

    handleSubmit(MODAL_TYPES.CHANGE_PASSWORD, payload);
  };

  return (
    <Form
      form={form}
      autoComplete="off"
      className="change-password-form"
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        label="Old Password"
        name="old_password"
        rules={[
          {
            required: true,
            message: "Please Enter your old password",
          },
        ]}
      >
        <Input placeholder="Enter your old password" />
      </Form.Item>
      <Form.Item
        label="New Password"
        name="new_password"
        rules={[
          {
            required: true,
            message: "Please enter your new password",
          },
          {
            min: 8,
            message: "Password must be at least 8 characters long",
          },
          {
            max: 15,
            message: "Password must be less than 15 characters",
          },
          {
            pattern: /[0-9]/,
            message: "Password should have at least one number",
          },
          {
            pattern: /[a-z]/,
            message: "Password should have at least one lowercase letter",
          },
          {
            pattern: /[A-Z]/,
            message: "Password should have at least one uppercase letter",
          },
          {
            pattern: /[!@#$%^&*]/,
            message: "Password should have at least one symbol ! @ # $ % ^ & *",
          },
          {
            pattern: /^[a-zA-Z0-9!@#$%^&*]+$/,
            message: "Use only these symbols ! @ # $ % ^ & * in your password",
          },
          {
            pattern: /^\S+$/,
            message: "Passwords cannot contain spaces",
          },
          {
            validator: handleValidPassword,
          },
        ]}
      >
        <Input.Password placeholder="Enter your new password" />
      </Form.Item>
      <Form.Item
        label="Confirm New Password"
        name="confirm_new_password"
        rules={[
          {
            required: true,
            message: "Please enter your confirm new password",
          },
          {
            validator: handleValidPassword,
          },
        ]}
      >
        <Input.Password placeholder="Enter your confirm new password" />
      </Form.Item>
      <Flex gap="small" justify="center">
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
        <Button danger type="primary" onClick={handleModal}>
          Cancel
        </Button>
      </Flex>
    </Form>
  );
};

export default PasswordUpdate;
