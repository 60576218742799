import { Button, Flex, Modal } from "antd";

const ConfirmPopup = ({
  title,
  open,
  onOk,
  onCancel,
  okText = "Confirm",
  cancelText = "Cancel",
  width = "auto",
  content = null,
}) => {
  return (
    <Modal
      centered
      className="confirm-popup"
      closeIcon={false}
      title={title}
      open={open}
      onCancel={onCancel}
      width={width}
      footer={
        <Flex gap={8} justify="center">
          <Button onClick={onOk} type="primary">
            {okText}
          </Button>
          <Button danger onClick={onCancel} type="primary">
            {cancelText}
          </Button>
        </Flex>
      }
    >
      {content}
    </Modal>
  );
};

export default ConfirmPopup;
