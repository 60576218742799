import { Button, Divider, Flex, Form, Input, Select, Typography } from "antd";
import { useEffect, useState } from "react";
import { UseAuthAxios } from "../../../utils";
import { useDispatch } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../redux/slice/globalLoaderSlice";
import Notification from "../../../components/notification";
import NumericInput from "../../../custom/numeric-input";
import OPTIONS from "../../../constant/register-choices.json";
import "./style.scss";

const {
  PROFILE_UPGRADE_OPTIONS,
  MEMBERSHIP_TYPE_OPTIONS,
  PAYMENT_MODE_OPTIONS,
} = OPTIONS;

const ProfileUpgrade = () => {
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();
  const [profileForm] = Form.useForm();
  const [upgradeForm] = Form.useForm();

  const [membersData, setMembersData] = useState([]);
  const [profileData, setProfileData] = useState({});

  const handleUpgradeReset = () => {
    if (!("membership_type" in profileData)) return;
    setProfileData({});
    upgradeForm.resetFields();
  };

  const handleFormReset = () => {
    handleUpgradeReset();
    profileForm.resetFields();
  };

  const handleUpgradeClear = () => {
    upgradeForm.resetFields();
    upgradeForm.setFieldsValue(profileData);
  };

  const handleProfileSubmit = ({ profile_index }) => {
    const data = membersData[profile_index];
    setProfileData(data);
    upgradeForm.setFieldsValue(data);
  };

  const handleProfileUpgrade = async ({
    target_membership,
    payment_mode,
    amount_received,
    notes,
  }) => {
    try {
      const payload = {
        profile_id: profileData.profile_id,
        upgrade_action: profileForm.getFieldValue("upgrade_action"),
        target_membership,
        payment_mode,
        amount_received,
        notes,
      };
      dispatch(startLoading());
      const { data } = await authAxios.post("upgrade_profile_details", payload);
      handleFormReset();
      Notification("success", data.message);
    } catch (error) {
      Notification(
        "error",
        error.response.data.message ||
          "Profile upgrade failed. Please try again later!"
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  const fetchProfileData = async () => {
    try {
      dispatch(startLoading());
      const { data } = await authAxios("/get_active_profile_list");
      setMembersData(data.data);
    } catch (error) {
      Notification(
        "error",
        error.response.data.message ||
          "Failed to get profile list. Please try again later!"
      );
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    fetchProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex vertical gap={16} className="profile-upgrade-container">
      <Typography.Title level={3}>Profile Upgrade</Typography.Title>
      <Form
        autoComplete="off"
        form={profileForm}
        className="profile-form"
        onFinish={handleProfileSubmit}
        onValuesChange={handleUpgradeReset}
      >
        <Flex justify="center" gap={24} wrap>
          <Form.Item
            label="Profile ID"
            name="profile_index"
            rules={[
              {
                required: true,
                message: "Please select a profile ID",
              },
            ]}
          >
            <Select showSearch allowClear placeholder="Select a profile ID">
              {membersData?.map(({ profile_id }, index) => (
                <Select.Option key={profile_id} value={index}>
                  {profile_id}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="upgrade_action"
            label="Upgrade"
            rules={[
              {
                required: true,
                message: "Please select an upgrade",
              },
            ]}
          >
            <Select
              placeholder="Select an upgrade"
              options={PROFILE_UPGRADE_OPTIONS}
            />
          </Form.Item>
          <Flex gap={8}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button danger type="primary" onClick={handleFormReset}>
              Clear
            </Button>
          </Flex>
        </Flex>
      </Form>
      <Divider />
      <Form
        autoComplete="off"
        form={upgradeForm}
        className="upgrade-form"
        disabled={!("membership_type" in profileData)}
        onFinish={handleProfileUpgrade}
      >
        <Flex vertical justify="center" align="center" gap={24} wrap>
          <Form.Item label="Current Membership" name="membership_type">
            <Input disabled placeholder="Current Membership" />
          </Form.Item>
          <Form.Item label="Membership End Date" name="membership_end_date">
            <Input disabled placeholder="Membership End Date" />
          </Form.Item>
          <Form.Item
            label="Target Membership"
            name="target_membership"
            rules={[
              {
                required: true,
                message: "Please select a target membership",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a target membership"
              options={MEMBERSHIP_TYPE_OPTIONS}
            />
          </Form.Item>
          <Form.Item
            label="Payment Mode"
            name="payment_mode"
            rules={[
              {
                required: true,
                message: "Please select a payment mode",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select a payment mode"
              options={PAYMENT_MODE_OPTIONS}
            >
              {membersData?.map(({ profile_id }) => (
                <Select.Option key={profile_id} value={profile_id}>
                  {profile_id}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Amount Received"
            name="amount_received"
            rules={[
              {
                required: true,
                message: "Please enter the amount received",
              },
            ]}
          >
            <NumericInput placeholder="Enter the amount received" />
          </Form.Item>
          <Form.Item label="Notes" name="notes" initialValue="">
            <Input.TextArea
              maxLength={500}
              count={{
                show: true,
              }}
              rows={3}
              placeholder="Enter your notes"
            />
          </Form.Item>
          <Flex gap={8}>
            <Button type="primary" htmlType="submit">
              Upgrade
            </Button>
            <Button danger type="primary" onClick={handleUpgradeClear}>
              Clear
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
};

export default ProfileUpgrade;
