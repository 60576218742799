import { Card, Layout } from "antd";
import { Outlet } from "react-router-dom";
import Footer from "../../views/footer";
import Header from "../../views/header";
// import GlobalLoader from "../../components/global-loader";
import "./style.scss";
import "../layout-style.scss";

const { Header: HeaderLayout, Content } = Layout;

const MainLayout = () => {
  return (
    <Layout className="layout-container main-layout">
      <HeaderLayout>
        <Header />
      </HeaderLayout>
      <Content>
        {/* <GlobalLoader /> */}
        <Card className="content-card">
          <Outlet />
        </Card>
        <Footer />
      </Content>
    </Layout>
  );
};

export default MainLayout;
