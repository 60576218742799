import {
  ExclamationCircleFilled,
  HeartFilled,
  HeartOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Button, Col, Flex, Modal, notification, Space, Tooltip } from "antd";
import { useMemo, useState } from "react";
import { FaPhoneAlt, FaUserCheck, FaUserPlus } from "react-icons/fa";
import { FaUserXmark } from "react-icons/fa6";
import ProfileReport from "./profile-report";
import { startLoading, stopLoading } from "../../redux/slice/globalLoaderSlice";

const FAVOURITE_LOADER = 1;
const REQUEST_LOADER = 2;
const REPORT_LOADER = 3;

const connectionStatusTooltip = {
  "": "Send Request",
  Accepted: "Request Accepted",
  Rejected: "Request Rejected",
  Requested: "Cancel Request",
};

const connectionDisabled = {
  Accepted: true,
  Rejected: true,
};

const connectionIcons = {
  "": <FaUserPlus className="send-request" />,
  Accepted: <FaUserCheck className="accepted" />,
  Rejected: <FaUserXmark className="rejected" />,
  Requested: <FaUserXmark className="cancel-request" />,
};

const confirmMessage = {
  1: "Are you sure you want to send a connection request?",
  2: "Are you sure you want to cancel this connection request?",
  3: "Are you sure you want to view the contact details?",
};

const ActionButton = (properties) => {
  const {
    authAxios,
    dispatch,
    profileData,
    handleProfile,
    handleProfileSection,
  } = properties;
  const { basic_details, other_details } = profileData;

  const [isReport, setIsReport] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [confirmStatus, setConfirmStatus] = useState(null);

  const handleFavourite = () => {
    setIsLoading(FAVOURITE_LOADER);
    const { profile_id } = basic_details;
    const isFavourite = other_details.is_favourite_profile;
    const url = isFavourite ? "/unshortlist_profile" : "/shortlist_profile";
    const method = isFavourite ? "delete" : "post";
    const favouritePayload = {
      profile_id,
    };
    authAxios({
      method,
      url,
      data: favouritePayload,
    })
      .then(() => {
        handleProfileSection("other_details", {
          is_favourite_profile: !isFavourite,
        });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(null));
  };

  const handleContactDetails = () => {
    dispatch(startLoading());
    const { profile_id } = basic_details;
    const contactPayload = {
      profile_id,
    };
    const reportUrl = "/view_profile_contact_details";
    authAxios
      .post(reportUrl, contactPayload)
      .then((response) => {
        handleProfile(response.data.data);
        notification.success({ message: response.data.message });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleConnectionRequest = () => {
    setIsLoading(REQUEST_LOADER);
    const isCancelRequest =
      other_details.profile_connection_status === "Requested";
    const url = isCancelRequest
      ? "/delete_connection_request"
      : "/send_connection_request";
    const requestPayload = isCancelRequest
      ? {
          profile_connection_id: other_details.profile_connection_id,
          target_profile_id: basic_details.profile_id,
        }
      : {
          requesting_profile_id: basic_details.profile_id,
        };
    const method = isCancelRequest ? "delete" : "post";

    authAxios({
      method,
      url,
      data: requestPayload,
    })
      .then((response) => {
        const connectionValue = isCancelRequest ? "" : "Requested";
        const connectionId = isCancelRequest
          ? undefined
          : response.data.data.connection_request_id;
        handleProfileSection("other_details", {
          profile_connection_status: connectionValue,
          profile_connection_id: connectionId,
        });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(null));
  };

  const handleConfirm = () => {
    confirmStatus === 3 ? handleContactDetails() : handleConnectionRequest();
    setConfirmStatus(null);
  };

  const handleReportCancel = () => setIsReport(false);

  const handleReportSend = ({ report }) => {
    setIsLoading(REPORT_LOADER);
    const reportUrl = "/report_account";
    const reportPayload = {
      reported_profile_id: basic_details.profile_id,
      report,
    };
    authAxios
      .post(reportUrl, reportPayload)
      .then((response) => {
        setIsReport(false);
        notification.success({ message: response.data.message });
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => setIsLoading(null));
  };

  const buttonConfigs = useMemo(() => {
    const {
      is_contact_viewed,
      is_favourite_profile,
      profile_connection_status,
    } = other_details;
    return [
      {
        key: "favourite",
        tooltip: is_favourite_profile ? "Unfavorite" : "Favourite",
        loading: isLoading === FAVOURITE_LOADER,
        icon: other_details?.is_favourite_profile ? (
          <HeartFilled />
        ) : (
          <HeartOutlined />
        ),
        onClick: handleFavourite,
        disabled: false,
      },
      {
        key: "request",
        tooltip: connectionStatusTooltip[profile_connection_status],
        loading: isLoading === REQUEST_LOADER,
        icon: connectionIcons[profile_connection_status],
        onClick: () => setConfirmStatus(profile_connection_status ? 2 : 1),
        disabled: connectionDisabled[profile_connection_status] || false,
      },
      {
        key: "report",
        tooltip: "Report this Profile",
        icon: <WarningOutlined />,
        onClick: () => setIsReport(true),
        disabled: false,
      },
      {
        key: "contact",
        tooltip: "View Contact Details",
        icon: <FaPhoneAlt className="phone-icon" />,
        onClick: () => setConfirmStatus(3),
        disabled: is_contact_viewed,
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, other_details]);

  return (
    <>
      <Col span={24} align="middle">
        <Space size="middle">
          {buttonConfigs.map((config) => (
            <Tooltip key={config.key} title={config.tooltip}>
              <Button
                shape="round"
                loading={config.loading}
                icon={config.icon}
                onClick={config.onClick}
                disabled={config.disabled}
              />
            </Tooltip>
          ))}
        </Space>
      </Col>
      <ProfileReport
        isReport={isReport}
        isLoading={isLoading}
        handleReportSend={handleReportSend}
        handleReportCancel={handleReportCancel}
      />
      <Modal
        centered
        className="confirm-modal"
        closeIcon={false}
        title={
          <Flex gap={8} justify="center">
            <ExclamationCircleFilled />
            {confirmMessage[confirmStatus]}
          </Flex>
        }
        open={!!confirmStatus}
        onCancel={() => setConfirmStatus(null)}
        footer={
          <Flex gap={8} justify="center">
            <Button type="primary" onClick={handleConfirm}>
              Yes
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => setConfirmStatus(null)}
            >
              No
            </Button>
          </Flex>
        }
      ></Modal>
    </>
  );
};

export default ActionButton;
