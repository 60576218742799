import React from "react";
import { Form, Input, Select, TimePicker } from "antd";
import CHOICES from "../constant/register-choices.json";

const { Option } = Select;

const {
  DAY_CHOICES,
  RASI_CHOICES,
  STAR_CHOICES,
  PADHAM_CHOICES,
  YES_NO_OPTIONS,
} = CHOICES;

const HoroscopeDetailsForm = ({ isEdit = false }) => {
  return (
    <>
      {!isEdit && (
        <Form.Item
          label="Time of Birth"
          name={["horoscope_details", "time_of_birth"]}
          rules={[
            {
              required: true,
              message: "Please select your time of birth",
            },
          ]}
        >
          <TimePicker
            autoFocus
            use12Hours
            format={"HH:mm"}
            placeholder="HH:MM"
          />
        </Form.Item>
      )}
      <Form.Item
        label="Day of Birth"
        name={["horoscope_details", "day_of_birth"]}
        rules={[
          {
            required: true,
            message: "Please select your day of birth",
          },
        ]}
      >
        <Select placeholder="Select your day of birth">
          {DAY_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Place Of Birth"
        name={["horoscope_details", "place_of_birth"]}
        rules={[
          {
            required: true,
            message: "Please enter your place of birth",
          },
        ]}
      >
        <Input placeholder="Enter your place of birth" />
      </Form.Item>
      <Form.Item
        label="Rasi"
        name={["horoscope_details", "rasi"]}
        rules={[
          {
            required: true,
            message: "Please select your rasi",
          },
        ]}
      >
        <Select showSearch placeholder="Select your rasi">
          {RASI_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Nachathiram"
        name={["horoscope_details", "star"]}
        rules={[
          {
            required: true,
            message: "Please select your nachathiram",
          },
        ]}
      >
        <Select showSearch placeholder="Select your nachathiram">
          {STAR_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Padham" name={["horoscope_details", "padham"]}>
        <Select>
          {PADHAM_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Lagnam" name={["horoscope_details", "lagnam"]}>
        <Select showSearch placeholder="Select your lagnam">
          {RASI_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Sevvai"
        name={["horoscope_details", "is_sevvai"]}
        rules={[
          {
            required: true,
            message: "Please select your Sevvai status",
          },
        ]}
      >
        <Select placeholder="Do you have Sevvai?">
          {YES_NO_OPTIONS?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Ragu / Kedhu"
        name={["horoscope_details", "is_ragu_kedhu"]}
        rules={[
          {
            required: true,
            message: "Please select your Ragu / Kedhu status",
          },
        ]}
      >
        <Select placeholder="Do you have Ragu / Kedhu?">
          {YES_NO_OPTIONS?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default HoroscopeDetailsForm;
