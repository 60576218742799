import { useEffect, useState } from "react";
import {
  Table,
  Form,
  Input,
  Button,
  Select,
  notification,
  Pagination,
  Typography,
  Flex,
  Modal,
  Descriptions,
} from "antd";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/slice/globalLoaderSlice";
import CustomButton from "../../../custom/success-button";
import "./style.scss";

const REPORTED_ISSUE_STATUS = {
  REPORTED: "Reported",
  RESOLVED: "Resolved",
};

const REPORT_TYPES = {
  account: {
    prefix: "RA",
    pageTitle: "Reported Account",
    modalTitle: "Reported Account Approval",
    statusLabel: "Reported Account Status",
    dataApi: "get_reported_accounts",
    approvalApi: "process_reported_accounts",
  },
  issues: {
    prefix: "RI",
    pageTitle: "Reported Issues",
    modalTitle: "Reported Issue Approval",
    statusLabel: "Reported Issue Status",
    dataApi: "get_reported_issues",
    approvalApi: "process_reported_issues",
  },
};

const STATUS_OPTIONS = Object.values(REPORTED_ISSUE_STATUS).map((value) => ({
  label: value,
  value,
}));

const PAGE_NUMBER = 1;
const RECORDS_PER_PAGE = 10;

const initialFormValues = {
  report_status: [REPORTED_ISSUE_STATUS.REPORTED],
  search_profile_id: "",
};

const Report = ({ type }) => {
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();
  const [searchForm] = Form.useForm();
  const [approvalForm] = Form.useForm();

  const [reportData, setReportData] = useState({
    data: [],
    pagination: {},
  });
  const [modalDetails, setModalDetails] = useState({
    visible: false,
    data: {},
  });

  const { prefix, pageTitle, modalTitle, statusLabel, dataApi, approvalApi } =
    REPORT_TYPES[type];

  const handleApprovalModal = (visible = false, data = {}) =>
    setModalDetails({
      visible,
      data,
    });

  const fetchReportData = async (page_number = PAGE_NUMBER) => {
    try {
      dispatch(startLoading());
      const { report_status, search_profile_id } = searchForm.getFieldsValue();
      const payload = {
        report_status,
        search_profile_id,
        page_number,
        records_per_page: RECORDS_PER_PAGE,
      };
      const { data } = await authAxios.post(dataApi, payload);
      setReportData({
        data: data.data,
        pagination: data.pagination_data,
      });
    } catch (error) {
      notification.error({ message: error.response.data.message });
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSubmitApproval = async ({ admin_notes }) => {
    try {
      dispatch(startLoading());
      const payload = {
        issue_id: modalDetails.data.id,
        admin_notes,
      };
      const { data } = await authAxios.post(approvalApi, payload);
      await fetchReportData();
      handleApprovalModal();
      notification.success({ message: data.message });
    } catch (error) {
      notification.error({ message: error.response.data.message });
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleViewProfile = (profile_id) =>
    window.open(`/search/${profile_id}`, "_blank");

  const handlePageChange = (pageNo) => fetchReportData(pageNo);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => `${prefix}-${text}`,
    },
    {
      title: "Profile Id",
      dataIndex: "reporter_profile_id",
    },
    {
      title: "Profile Name",
      dataIndex: "reporter_profile_name",
    },
    ...(type === "account"
      ? [
          {
            title: "Reported Profile ID",
            dataIndex: "reported_profile_id",
          },
          {
            title: "Reported Profile Name",
            dataIndex: "reported_profile_name",
          },
        ]
      : []),
    {
      title: "Report",
      dataIndex: type === "issues" ? "reported_issue" : "report",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Report Status",
      dataIndex: "is_report_resolved",
    },
    {
      title: "Admin Notes",
      dataIndex: "admin_notes",
    },
    {
      title: "Action",
      render: (data) => (
        <Flex gap={8} justify="center" wrap>
          {data.is_report_resolved === "Pending" && (
            <CustomButton
              success
              type="primary"
              onClick={() => handleApprovalModal(true, data)}
            >
              Process
            </CustomButton>
          )}
          <Button
            type="primary"
            onClick={() => {
              handleViewProfile(data?.reporter_profile_id);
            }}
          >
            View Profile
          </Button>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <Flex vertical gap={18} className="report-container">
      <Typography.Title level={3}>{pageTitle}</Typography.Title>
      <Form
        autoComplete="off"
        initialValues={initialFormValues}
        form={searchForm}
        onFinish={() => fetchReportData()}
      >
        <Flex justify="center" gap={24} wrap>
          <Form.Item
            label={statusLabel}
            name="report_status"
            rules={[
              {
                required: true,
                message: "Please select a profile  status",
              },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              placeholder="Select profile verification status"
              options={STATUS_OPTIONS}
            />
          </Form.Item>
          <Form.Item name="search_profile_id" label="Search Profile ID">
            <Input placeholder="Enter Profile ID" />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Flex>
      </Form>
      <Table
        columns={columns}
        dataSource={reportData.data}
        pagination={false}
        bordered
        scroll={{
          x: 920,
        }}
        rowKey="id"
      />
      <Pagination
        total={reportData.pagination.total_objects}
        current={reportData.pagination.requested_page_number}
        onChange={handlePageChange}
      />
      <Modal
        centered
        className="report-approval-modal"
        closable={false}
        maskClosable={false}
        title={modalTitle}
        open={modalDetails.visible}
        onCancel={() => handleApprovalModal()}
        footer={null}
        afterClose={() => approvalForm.resetFields()}
      >
        <Descriptions
          bordered={false}
          column={1}
          items={[
            {
              label: "Report ID",
              children: `${prefix}-${modalDetails.data.id}`,
            },
            {
              label: "Profile ID",
              children: modalDetails.data.reporter_profile_id,
            },
            {
              label: "Name",
              children: modalDetails.data.reporter_profile_name,
            },
          ]}
        />
        <Form
          layout="vertical"
          autoComplete="off"
          form={approvalForm}
          onFinish={handleSubmitApproval}
        >
          <Form.Item
            initialValue=""
            label="Admin Notes"
            name="admin_notes"
            rules={[
              {
                required: true,
                message: "Please enter the notes",
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              count={{
                show: true,
              }}
              maxLength={250}
              placeholder="Enter the notes"
            />
          </Form.Item>
          <Flex gap={8} justify="center">
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
            <Button danger onClick={() => handleApprovalModal()} type="primary">
              Cancel
            </Button>
          </Flex>
        </Form>
      </Modal>
    </Flex>
  );
};

export default Report;
