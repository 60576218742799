import { createSlice } from "@reduxjs/toolkit";

const globalLoaderSlice = createSlice({
  name: "globalLoader",
  initialState: {
    isLoading: false,
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { startLoading, stopLoading } = globalLoaderSlice.actions;

export default globalLoaderSlice.reducer;
