import { useEffect, useState } from "react";
import {
  Table,
  Form,
  Col,
  Input,
  Button,
  Row,
  Modal,
  Select,
  notification,
  Flex,
  Pagination,
  Typography,
  Descriptions,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import CustomButton from "../../../custom/success-button";
import {
  startLoading,
  stopLoading,
} from "../../../redux/slice/globalLoaderSlice";
import "./style.scss";

const { Option } = Select;

const getRefreshToken = () => localStorage.getItem("refreshToken");

const DeleteProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [deleteData, setDeleteData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileDetails, setIsProfileDetails] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});

  const delete_request_status = ["Requested", "Accepted", "Rejected"];
  const Approval_status = [
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
  ];
  const initialValues = { request_status: ["Requested"], profile_id: "" };

  const columns = [
    {
      title: "Profile ID",
      dataIndex: "profile_id",
    },
    {
      title: "Profile Name",
      dataIndex: "profile_name",
    },
    {
      title: "Request Status",
      dataIndex: "request_status",
      render: (data) => <span className={data}>{data}</span>,
    },
    {
      title: "Request Notes",
      dataIndex: "request_notes",
    },
    {
      title: "Created At",
      dataIndex: "requested_at",
    },
    {
      title: "Admin ID",
      dataIndex: "admin_profile_id",
    },
    {
      title: "Admin Name",
      dataIndex: "admin_name",
    },
    {
      title: "Admin Notes",
      dataIndex: "admin_notes",
    },
    {
      title: "Processed At",
      dataIndex: "processed_at",
    },
    {
      title: "Action",
      render: (data) => (
        <Flex gap={8} justify="center" wrap>
          {data.request_status === "Requested" && (
            <CustomButton
              success
              type="primary"
              onClick={() => showModal(data)}
            >
              Approval
            </CustomButton>
          )}
          {data.request_status === "Accepted" ? (
            <CustomButton
              warning
              type="primary"
              onClick={() => handleProfileDetails(data.profile_data)}
            >
              Profile Details
            </CustomButton>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                handleViewProfile(data?.profile_id);
              }}
            >
              View Profile
            </Button>
          )}
        </Flex>
      ),
    },
  ];

  const descriptionItems = [
    {
      key: "father_name",
      label: "Father's Name",
      children: <span>{profileDetails.father_name}</span>,
    },
    {
      key: "mother_name",
      label: "Mother's Name",
      children: <span>{profileDetails.mother_name}</span>,
    },
    {
      key: "city",
      label: "City",
      children: <span>{profileDetails.city}</span>,
    },
    {
      key: "contact_number_1",
      label: "Contact Number 1",
      children: <span>{profileDetails.contact_number_1}</span>,
    },
    {
      key: "contact_number_2",
      label: "Contact Number 2",
      children: <span>{profileDetails.contact_number_2}</span>,
    },
    {
      key: "user_email",
      label: "Email",
      children: <span>{profileDetails.user_email}</span>,
    },
    {
      key: "date_of_joining_in_ist",
      label: "Date of Joining",
      children: <span>{profileDetails.date_of_joining_in_ist}</span>,
    },
  ];

  const showModal = (data) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setSelectedData({});
    setIsModalOpen(false);
    form2.resetFields();
  };

  const fetchDeleteData = (payload) => {
    dispatch(startLoading());
    const url = "/list_profile_delete_request";

    authAxios
      .post(url, payload)
      .then((response) => {
        setDeleteData(response.data.data);
        setPagination(response.data.pagination_data);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const onFinishSearch = (formValues) => {
    const payload = {
      search_profile_id: formValues.profile_id,
      request_status: formValues.request_status,
      page_number: 1,
      records_per_page: 10,
    };
    fetchDeleteData(payload);
  };

  const handleSessionExpired = () => {
    alert("Your session has expired. Please log in again.");
    navigate("/login");
  };

  const handleViewProfile = (profile_id) => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      window.open(`/search/${profile_id}`, "_blank");
    } else {
      handleSessionExpired();
    }
  };

  const handlePageChange = (pageNo) => {
    const { request_status, profile_id } = form1.getFieldsValue();
    const payload = {
      search_profile_id: profile_id,
      request_status,
      page_number: pageNo,
      records_per_page: 10,
    };
    fetchDeleteData(payload);
  };

  const onFinish = (formValues) => {
    dispatch(startLoading());
    const { request_status } = form1.getFieldsValue();

    const url = "/delete_profile";
    const payload = {
      profile_id: selectedData.profile_id,
      admin_notes: formValues.Notes,
      approval_status: formValues.approval_status,
    };
    authAxios({ method: "delete", url, data: payload })
      .then(() => {
        const payload = {
          search_profile_id: "",
          request_status,
          page_number: 1,
          records_per_page: 10,
        };
        fetchDeleteData(payload);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
    handleCancel();
  };

  const handleProfileDetails = (details) => {
    setIsProfileDetails(!isProfileDetails);
    setProfileDetails(details);
  };

  useEffect(() => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const payload = {
        search_profile_id: "",
        request_status: ["Requested"],
        page_number: 1,
        records_per_page: 10,
      };
      fetchDeleteData(payload);
    } else {
      handleSessionExpired();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography.Title level={3} className="page-title">
        Profile Delete
      </Typography.Title>
      <Form
        autoComplete="off"
        className="delete-search"
        initialValues={initialValues}
        form={form1}
        onFinish={onFinishSearch}
      >
        <Row align="middle" gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item
              label="Delete Status"
              name="request_status"
              rules={[
                {
                  required: true,
                  message: "Please select profile  status",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select profile verification status"
              >
                {delete_request_status.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item name="profile_id" label="Search Profile ID">
              <Input placeholder="Enter Profile ID" />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        columns={columns}
        dataSource={deleteData}
        pagination={false}
        bordered
        scroll={{
          x: 920,
        }}
        rowKey="profile_username"
      />
      <br />
      <Pagination
        total={pagination.total_objects}
        current={pagination.requested_page_number}
        onChange={handlePageChange}
      />
      <Modal
        className="delete-approval-modal"
        title="Delete Request Approval"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <p className="user-details">
          <strong>
            Profile ID: <span>{selectedData.profile_id}</span>
          </strong>
        </p>
        <p className="user-details">
          <strong>
            Name: <span>{selectedData.profile_name}</span>
          </strong>
        </p>
        <Form
          autoComplete="off"
          form={form2}
          initialValues={selectedData}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            initialValue=""
            label="Notes"
            name="Notes"
            rules={[
              {
                required: true,
                message: "Please enter the notes",
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              count={{
                show: true,
              }}
              maxLength={250}
              placeholder="Enter the Notes"
            />
          </Form.Item>
          <Form.Item
            name="approval_status"
            label="Approval Status"
            rules={[
              {
                required: true,
                message: "Please select profile verification status",
              },
            ]}
          >
            <Select placeholder="Select your approval status">
              {Approval_status?.map(({ value, label }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Flex gap={8} justify="center">
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
            <Button danger onClick={handleCancel} type="primary">
              Cancel
            </Button>
          </Flex>
        </Form>
      </Modal>
      <Modal
        footer={null}
        open={isProfileDetails}
        onCancel={() => handleProfileDetails({})}
      >
        <Descriptions
          bordered
          className="profile-details-description"
          column={1}
          items={descriptionItems}
          title="Profile Details"
        />
      </Modal>
    </>
  );
};

export default DeleteProfile;
