import { useEffect, useState } from "react";
import { Table, Button, notification, Pagination, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import "./style.scss";
import {
  startLoading,
  stopLoading,
} from "../../../redux/slice/globalLoaderSlice";

const getRefreshToken = () => localStorage.getItem("refreshToken");

const DeactivatedProfiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authAxios = UseAuthAxios();

  const [deactivatedData, setDeactivatedData] = useState([]);
  const [pagination, setPagination] = useState({});

  const columns = [
    {
      title: "Profile ID",
      dataIndex: "profile_id",
    },
    {
      title: "Profile Name",
      dataIndex: "profile_name",
    },
    {
      title: "Membership Type",
      dataIndex: "membership_type",
    },
    {
      title: "Deactivate At",
      dataIndex: "deactivate_at",
    },
    {
      title: "Duration in Days",
      dataIndex: "duration_in_days",
    },
    {
      title: "Action",
      render: (data) => (
        <Button
          type="primary"
          onClick={() => {
            handleViewProfile(data?.profile_id);
          }}
        >
          View Profile
        </Button>
      ),
    },
  ];

  const fetchDeactivatedData = (payload) => {
    dispatch(startLoading());
    const url = "/list_deactivated_profiles";

    authAxios
      .post(url, payload)
      .then((response) => {
        setDeactivatedData(response.data.data.results);
        setPagination(response.data.data.pagination_data);
      })
      .catch((err) =>
        notification.error({ message: err.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleSessionExpired = () => {
    alert("Your session has expired. Please log in again.");
    navigate("/login");
  };

  const handleViewProfile = (profile_id) => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      window.open(`/search/${profile_id}`, "_blank");
    } else {
      handleSessionExpired();
    }
  };

  const handlePageChange = (pageNo) => {
    const payload = {
      page_number: pageNo,
      records_per_page: 10,
    };
    fetchDeactivatedData(payload);
  };

  useEffect(() => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      const payload = {
        page_number: 1,
        records_per_page: 10,
      };
      fetchDeactivatedData(payload);
    } else {
      handleSessionExpired();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography.Title level={3} className="page-title">
        Deactivated Profiles
      </Typography.Title>
      <Table
        columns={columns}
        dataSource={deactivatedData}
        pagination={false}
        bordered
        scroll={{
          x: 920,
        }}
        rowKey="profile_id"
      />
      <br />
      <Pagination
        total={pagination.total_objects}
        current={pagination.requested_page_number}
        onChange={handlePageChange}
      />
    </>
  );
};

export default DeactivatedProfiles;
