import React, { useEffect, useState } from "react";
import { Col, Form, Input, Radio, Row, Select } from "antd";
import CHOICES from "../constant/register-choices.json";

const { Option } = Select;
const { Group } = Radio;
const { TextArea } = Input;

const {
  FAMILY_STATUS_CHOICES,
  FAMILY_VALUE_CHOICES,
  FAMILY_TYPE_CHOICES,
  MONTHLY_INCOME_CHOICES,
  ASSET_VALUE_CHOICES,
} = CHOICES;

const SIBLING_COUNTS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const FamilyDetailsForm = ({ form }) => {
  const [sisterCount, setSisterCount] = useState(0);
  const [brotherCount, setBrotherCount] = useState(0);

  const handleCount = (value, setState, fieldName) => {
    setState(value);
    form.setFieldsValue({
      family_details: {
        [fieldName]: 0,
      },
    });
  };

  useEffect(() => {
    const {
      family_details: { no_of_sisters, no_of_brothers },
    } = form.getFieldsValue();
    setSisterCount(no_of_sisters || 0);
    setBrotherCount(no_of_brothers || 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form.Item
        label="Father Name"
        name={["family_details", "father_name"]}
        rules={[
          {
            required: true,
            message: "Please enter your father name",
          },
        ]}
      >
        <Input placeholder="Enter your father name" />
      </Form.Item>
      <Form.Item
        label="Father Occupation"
        name={["family_details", "father_occupation"]}
      >
        <Input placeholder="Enter your father occupation" />
      </Form.Item>
      <Form.Item
        label="Mother Name"
        name={["family_details", "mother_name"]}
        rules={[
          {
            required: true,
            message: "Please enter your mother name",
          },
        ]}
      >
        <Input placeholder="Enter your mother name" />
      </Form.Item>
      <Form.Item
        label="Mother Occupation"
        name={["family_details", "mother_occupation"]}
      >
        <Input placeholder="Enter your mother occupation" />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="No of Sisters"
            name={["family_details", "no_of_sisters"]}
          >
            <Select
              onChange={(value) =>
                handleCount(value, setSisterCount, "no_sisters_married")
              }
            >
              {SIBLING_COUNTS?.map((count) => (
                <Option key={count} value={count}>
                  {count}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="No of married sisters"
            name={["family_details", "no_sisters_married"]}
          >
            <Select disabled={!sisterCount}>
              {SIBLING_COUNTS.reduce((options, count, index) => {
                if (index <= sisterCount) {
                  options.push(
                    <Option key={count} value={count}>
                      {count}
                    </Option>
                  );
                }
                return options;
              }, [])}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="No of Brothers"
            name={["family_details", "no_of_brothers"]}
          >
            <Select
              onChange={(value) =>
                handleCount(value, setBrotherCount, "no_brothers_married")
              }
            >
              {SIBLING_COUNTS?.map((count) => (
                <Option key={count} value={count}>
                  {count}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="No of married brothers"
            name={["family_details", "no_brothers_married"]}
          >
            <Select disabled={!brotherCount}>
              {SIBLING_COUNTS.reduce((options, count, index) => {
                if (index <= brotherCount) {
                  options.push(
                    <Option key={count} value={count}>
                      {count}
                    </Option>
                  );
                }
                return options;
              }, [])}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Family Status"
        name={["family_details", "family_status"]}
        rules={[
          {
            required: true,
            message: "Please choose your family status",
          },
        ]}
      >
        <Group>
          {FAMILY_STATUS_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="Family Value"
        name={["family_details", "family_value"]}
        rules={[
          {
            required: true,
            message: "Please choose your family value",
          },
        ]}
      >
        <Group>
          {FAMILY_VALUE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="Family Type"
        name={["family_details", "family_type"]}
        rules={[
          {
            required: true,
            message: "Please choose your family type",
          },
        ]}
      >
        <Group>
          {FAMILY_TYPE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="Family Income (Per Month)"
        name={["family_details", "family_income_in_rs"]}
        rules={[
          {
            required: true,
            message: "Please choose your family income",
          },
        ]}
      >
        <Select showSearch placeholder="Select your monthly family income">
          {MONTHLY_INCOME_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="About Family" name={["family_details", "about_family"]}>
        <TextArea
          maxLength={250}
          count={{
            show: true,
          }}
          rows={3}
          placeholder="Describe about your family"
        />
      </Form.Item>
      <Form.Item
        label="Own House"
        name={["family_details", "is_own_house"]}
        rules={[
          {
            required: true,
            message: "Please select if you own a house",
          },
        ]}
      >
        <Select placeholder="Do you own a house?">
          <Option value="yes">Yes</Option>
          <Option value="no">No</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Total Asset Value"
        name={["family_details", "total_asset_value"]}
        rules={[
          {
            required: true,
            message: "Please choose your family total asset",
          },
        ]}
      >
        <Select placeholder="Select your asset value">
          {ASSET_VALUE_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Asset Details"
        name={["family_details", "asset_details"]}
      >
        <TextArea
          maxLength={250}
          count={{
            show: true,
          }}
          rows={3}
          placeholder="Enter your asset details"
        />
      </Form.Item>
    </>
  );
};

export default FamilyDetailsForm;
