import React from "react";
import { Form, Input, Select } from "antd";
import CHOICES from "../constant/register-choices.json";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const {
  COUNTRY_LIST,
  EDUCATION_LIST,
  MONTHLY_INCOME_CHOICES,
  OCCUPATION_CATEGORY,
  OCCUPATION_LIST,
} = CHOICES;

const EducationOccupationDetailsForm = () => {
  return (
    <>
      <Form.Item label="College" name={["education_details", "college"]}>
        <TextArea
          maxLength={250}
          count={{
            show: true,
          }}
          rows={2}
          placeholder="Enter you college"
        />
      </Form.Item>
      <Form.Item
        label="Education"
        name={["education_details", "education"]}
        rules={[
          {
            required: true,
            message: "Please select your education",
          },
        ]}
      >
        <Select showSearch mode="multiple" placeholder="Select your education">
          {EDUCATION_LIST.map((value) => (
            <Option key={value} value={value}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Education Details"
        name={["education_details", "education_details"]}
      >
        <TextArea
          maxLength={250}
          count={{
            show: true,
          }}
          rows={2}
          placeholder="Enter you education details"
        />
      </Form.Item>
      <Form.Item
        label="Occupation"
        name={["education_details", "occupation"]}
        rules={[
          {
            required: true,
            message: "Please select your occupation",
          },
        ]}
      >
        <Select showSearch placeholder="Select your occupation">
          {OCCUPATION_CATEGORY?.map((group) => (
            <OptGroup key={group} label={group}>
              {OCCUPATION_LIST[group].map((value) => (
                <Option key={value} value={value}>
                  {value}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Job Title / Details"
        name={["education_details", "job_title"]}
      >
        <TextArea
          maxLength={350}
          count={{
            show: true,
          }}
          rows={2}
          placeholder="Enter you job title / details"
        />
      </Form.Item>
      <Form.Item
        label="Company Name"
        name={["education_details", "company_name"]}
      >
        <TextArea
          maxLength={150}
          count={{
            show: true,
          }}
          rows={2}
          placeholder="Enter you company name"
        />
      </Form.Item>
      <Form.Item
        label="Currently Employed Country"
        name={["education_details", "currently_employed_country"]}
      >
        <Select showSearch placeholder="Select your employed country">
          {COUNTRY_LIST?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Income (Per Month)"
        name={["education_details", "monthly_income_in_rs"]}
        rules={[
          {
            required: true,
            message: "Please select your monthly income",
          },
        ]}
      >
        <Select showSearch placeholder="Select your monthly income">
          {MONTHLY_INCOME_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default EducationOccupationDetailsForm;
