import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import "./style.scss";

const GlobalLoader = () => {
  const isLoading = useSelector((state) => state.globalLoader.isLoading);

  if (!isLoading) return null;

  return (
    <Spin
      className="global-loader"
      style={{
        color: "#237804",
      }}
      indicator={<LoadingOutlined />}
      fullscreen
    />
  );
};

export default GlobalLoader;
