import React from "react";
import { Form, Input, Select } from "antd";
import CHOICES from "../constant/register-choices.json";
import NumericInput from "../custom/numeric-input";

const { Option } = Select;

const { COUNTRY_LIST, STATE_LIST } = CHOICES;

const ContactDetailsForm = () => {
  return (
    <>
      <Form.Item label="Country" name={["contact_details", "country"]}>
        <Select showSearch placeholder="Select your country">
          {COUNTRY_LIST?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="State"
        name={["contact_details", "state"]}
        rules={[
          {
            required: true,
            message: "Please select your state",
          },
        ]}
      >
        <Select showSearch placeholder="Select your state">
          {STATE_LIST?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="City / Town"
        name={["contact_details", "city"]}
        rules={[
          {
            required: true,
            message: "Please enter your city / town",
          },
        ]}
      >
        <Input placeholder="Enter your city / town" />
      </Form.Item>
      <Form.Item
        label="Native"
        name={["contact_details", "native"]}
        rules={[
          {
            required: true,
            message: "Please enter your native place",
          },
        ]}
      >
        <Input placeholder="Enter your native place" />
      </Form.Item>
      <Form.Item
        label="Contact Number 1"
        name={["contact_details", "contact_number_1"]}
        rules={[
          { required: true, message: "Please enter your contact number 1" },
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter a valid contact number 1",
          },
        ]}
      >
        <NumericInput
          maxLength={10}
          addonBefore="+91"
          placeholder="Enter your contact number 1"
        />
      </Form.Item>
      <Form.Item
        label="Contact Number 2"
        name={["contact_details", "contact_number_2"]}
        dependencies={[["contact_details", "contact_number_1"]]}
        rules={[
          {
            pattern: /^[0-9]{10}$/,
            message: "Please enter a valid contact number 2",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                !value ||
                value !== getFieldValue(["contact_details", "contact_number_1"])
              ) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  "Contact number 2 must be different from contact number 1"
                )
              );
            },
          }),
        ]}
      >
        <NumericInput
          maxLength={10}
          addonBefore="+91"
          placeholder="Enter your contact number 2"
        />
      </Form.Item>
    </>
  );
};

export default ContactDetailsForm;
