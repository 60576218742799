/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  notification,
  Image,
  Table,
  Pagination,
  Modal,
  Typography,
} from "antd";
import { UseAuthAxios } from "../../utils";
import ReportImage from "../../image/report.jpg";
import "./style.scss";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../redux/slice/globalLoaderSlice";

const { TextArea } = Input;

const ReportIssue = () => {
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();
  const [form] = Form.useForm();

  const [reportData, setReportData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [isReportModal, setIsReportModal] = useState(false);

  const initialPayload = {
    page_number: 1,
    records_per_page: 10,
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => `RI-${text}`,
    },
    {
      title: "Issue",
      dataIndex: "reported_issue",
    },
    {
      title: "Status",
      dataIndex: "report_status",
    },
    {
      title: "Created At",
      dataIndex: "processed_at",
    },
  ];

  const fetchReportData = (payload) => {
    dispatch(startLoading());

    authAxios
      .post("list_user_reported_issue", payload)
      .then((response) => {
        const { results, pagination_data } = response.data.data;
        setReportData(results);
        setPageData(pagination_data);
      })
      .catch((error) =>
        notification.error({ message: error.response?.data?.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handlePageChange = (pageNo, pageSize) => {
    const payload = {
      page_number: pageNo,
      records_per_page: pageSize,
    };
    fetchReportData(payload);
  };

  const handleReportModal = () => {
    form.resetFields();
    setIsReportModal(!isReportModal);
  };

  const handleReportSend = (payload) => {
    dispatch(startLoading());

    authAxios
      .post("report_issue", payload)
      .then((response) => {
        handleReportModal();
        fetchReportData(initialPayload);
        notification.success({
          message: response.data.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.response.data.message,
        });
        dispatch(stopLoading());
      });
  };

  useEffect(() => {
    fetchReportData(initialPayload);
  }, []);

  return (
    <>
      <Typography.Title level={3} className="page-title">
        Report Issue
      </Typography.Title>
      <Flex vertical gap={16}>
        <Flex justify="end">
          <Button type="primary" onClick={handleReportModal}>
            Report New Issue
          </Button>
        </Flex>
        <Table
          className="report-issue-table"
          columns={columns}
          dataSource={reportData}
          pagination={false}
          bordered
          scroll={{
            x: 560,
          }}
          rowKey="id"
        />
        {reportData.length ? (
          <Pagination
            className="report-issue-pagination"
            current={pageData?.requested_page_number}
            onChange={handlePageChange}
            pageSize={pageData?.requested_records_per_page}
            pageSizeOptions={[10, 20, 30]}
            showSizeChanger
            total={pageData?.total_objects}
          />
        ) : null}
      </Flex>
      <Modal
        className="report-issue-modal"
        title="Report what happened below we are truly sorry!"
        open={isReportModal}
        footer={null}
        closable={null}
        maskClosable={false}
        closeIcon={null}
      >
        <Image preview={false} src={ReportImage} alt="report-image" />
        <Form
          form={form}
          layout="vertical"
          onFinish={handleReportSend}
          autoComplete="off"
        >
          <Form.Item
            name="reported_issue"
            rules={[
              {
                required: true,
                message: "Please enter your issue",
              },
              {
                min: 50,
                message: "Please enter at least 50 characters",
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <TextArea
              className="report-textarea"
              rows={6}
              count={{
                show: true,
              }}
              maxLength={250}
              placeholder="Let us know what's going on"
            />
          </Form.Item>
          <Flex gap={8} justify="center">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button danger type="primary" onClick={handleReportModal}>
              Cancel
            </Button>
          </Flex>
        </Form>
      </Modal>
    </>
  );
};

export default ReportIssue;
