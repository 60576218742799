import React, { useState } from "react";
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from "antd";
import { MailTwoTone } from "@ant-design/icons";
import { encryptData, UseAxios } from "../../utils";
import BasicDetailsForm from "../../components/basic-details";

const { Title } = Typography;

const Step1Form = (props) => {
  const { formData, handleFormData, isLoading, setIsLoading, verifiedProfile } =
    props;

  const form = Form.useForm()[0];
  const [form1Data, setForm1Data] = useState({});
  const [otp, setOtp] = useState("");

  const handleUserValidation = (formValues) => {
    setIsLoading(true);
    const { email_id, password } = formValues.basic_details;
    const encryptedPassword = encryptData(password);
    const url = "validate_email_and_password";
    const payload = {
      email_id,
      password: encryptedPassword,
    };

    UseAxios.post(url, payload)
      .then(() => {
        handleSendEmailCode(formValues);
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({ message: error.response.data.message });
      });
  };

  const handleSendEmailCode = (data) => {
    const { email_id, first_name, last_name, number } = data.basic_details;
    const url = "request_otp_for_email_verification";
    const emailPayload = {
      email_id,
      user_full_name: first_name + last_name,
      mobile_number: number,
    };
    UseAxios.post(url, emailPayload)
      .then(() => {
        setForm1Data(data);
      })
      .catch((error) => {
        notification.error({ message: error.response.data.message });
      })
      .finally(() => setIsLoading(false));
  };

  const handleEmailVerifyCancel = () => {
    setOtp("");
    setForm1Data({});
  };

  const handleVerifyEmailCode = () => {
    setIsLoading(true);
    const { email_id } = form1Data.basic_details;
    const url = "verify_email_with_otp";
    const otpPayload = {
      email_id,
      otp,
    };
    UseAxios.post(url, otpPayload)
      .then((response) => {
        handleFormData(form1Data, 1);
        handleEmailVerifyCancel();
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({ message: error.response.data.message });
      });
  };

  const onFinish = (formValues) => {
    const { email_id } = formValues.basic_details;
    if (verifiedProfile === email_id) {
      handleFormData(form1Data, 1);
    } else {
      handleUserValidation(formValues);
    }
  };

  return (
    <>
      <Form
        labelWrap
        form={form}
        autoComplete="off"
        scrollToFirstError
        onFinish={onFinish}
        labelCol={{ span: 8 }}
        initialValues={formData}
        wrapperCol={{ span: 14 }}
      >
        <BasicDetailsForm form={form} />
        <Flex justify="center" gap="small">
          <Button disabled>Previous</Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Continue
          </Button>
        </Flex>
      </Form>
      <Modal
        centered
        closeIcon={false}
        maskClosable={false}
        className="email-verify-modal"
        open={"basic_details" in form1Data}
        footer={[
          <Row key="email-verify-footer" justify="center" gutter={[8, 0]}>
            <Col>
              <Button
                type="primary"
                loading={isLoading}
                disabled={otp.length !== 6}
                onClick={handleVerifyEmailCode}
              >
                Send
              </Button>
            </Col>
            <Col>
              <Button danger type="primary" onClick={handleEmailVerifyCancel}>
                Cancel
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col span={24} align="center">
            <MailTwoTone />
          </Col>
          <Col span={24}>
            <Title level={4} align="center">
              Verify your email address
            </Title>
          </Col>
          <Col span={24} align="center">
            A verification email has been sent to your inbox. Please check your
            email and enter the verification code below.
          </Col>
          <Col align="center">
            <Input
              value={otp}
              maxLength={6}
              placeholder="Enter the code"
              onChange={(event) => setOtp(event.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Step1Form;
