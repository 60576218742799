import { Button, Flex, Form, Typography } from "antd";
import {
  FamilyDetailsForm,
  PartnerPreferenceDetailsForm,
  PhysicalDetailsForm,
} from "../../components";

const { Title } = Typography;

const Step3Form = (properties) => {
  const { formData, handleFormData, isLoading, setIsLoading } = properties;

  const [form] = Form.useForm();

  const handlePrevious = () => {
    const fieldsValue = form.getFieldsValue();
    handleFormData(fieldsValue, 1);
  };

  const onFinish = (formValues) => {
    setIsLoading(true);
    handleFormData(formValues, 3);
  };

  return (
    <Form
      labelWrap
      form={form}
      autoComplete="off"
      scrollToFirstError
      onFinish={onFinish}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 14 }}
      initialValues={formData}
    >
      <Title level={4}>Physical Details</Title>
      <PhysicalDetailsForm form={form} />
      <Title level={4}>Family Details</Title>
      <FamilyDetailsForm form={form} />
      <Title level={4}>Partner Preference</Title>
      <PartnerPreferenceDetailsForm />
      <Flex justify="center" gap="small">
        <Button onClick={handlePrevious}>Previous</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Register
        </Button>
      </Flex>
    </Form>
  );
};

export default Step3Form;
