import React, { useEffect, useState } from "react";
import { Flex, Form, Radio, Select, Upload } from "antd";
import CHOICES from "../constant/register-choices.json";
import { PlusOutlined } from "@ant-design/icons";
import { handleFileValidation } from "../utils/helper";

const { Option } = Select;
const { Group } = Radio;

const {
  SKIN_COLOR_CHOICES,
  BODY_TYPE_CHOICES,
  DIET_OPTIONS,
  BLOOD_GROUP,
  HEIGHT_CHOICES,
  WEIGHT_CHOICES,
} = CHOICES;

const MAX_UPLOAD_COUNT = 3;

const PhysicalDetailsForm = ({ form, isEdit = false }) => {
  const [isPhotos, setIsPhotos] = useState(true);

  const handleUpload = ({ fileList }) => {
    setIsPhotos(fileList.length < MAX_UPLOAD_COUNT);
  };

  useEffect(() => {
    setIsPhotos(
      form.getFieldValue(["physical_details", "profile_media_files"])?.length <
        MAX_UPLOAD_COUNT
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isEdit && (
        <Form.Item
          label="Profile Photos"
          name={["physical_details", "profile_media_files"]}
          valuePropName="fileList"
          getValueFromEvent={({ fileList }) => fileList}
        >
          <Upload
            accept=".jpeg,.jpg,.png"
            beforeUpload={handleFileValidation}
            listType="picture-card"
            maxCount={MAX_UPLOAD_COUNT}
            multiple
            onChange={handleUpload}
            showUploadList={{ showPreviewIcon: false }}
          >
            {isPhotos && (
              <Flex gap={8}>
                <PlusOutlined />
                <span>Upload</span>
              </Flex>
            )}
          </Upload>
        </Form.Item>
      )}
      <Form.Item
        label="Skin Tone"
        name={["physical_details", "skin_tone"]}
        rules={[
          {
            required: true,
            message: "Please choose your skin tone",
          },
        ]}
      >
        <Group>
          {SKIN_COLOR_CHOICES?.map(({ value, label }, index) => (
            <Radio key={value} value={value} autoFocus={index === 0}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="Body Type"
        wrapperCol={{ span: 24 }}
        name={["physical_details", "body_type"]}
        rules={[
          {
            required: true,
            message: "Please choose your body type",
          },
        ]}
      >
        <Group>
          {BODY_TYPE_CHOICES?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item
        label="Diet Type"
        name={["physical_details", "diet"]}
        rules={[
          {
            required: true,
            validator: (_, value) => {
              if (value === null || value === undefined) {
                return Promise.reject(new Error("Please choose your diet"));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Group>
          {DIET_OPTIONS?.map(({ value, label }) => (
            <Radio key={value} value={value}>
              {label}
            </Radio>
          ))}
        </Group>
      </Form.Item>
      <Form.Item label="Blood Group" name={["physical_details", "blood_group"]}>
        <Select showSearch placeholder="Please select your blood group">
          {BLOOD_GROUP?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Height"
        name={["physical_details", "height_in_cm"]}
        rules={[{ required: true, message: "Please enter your height" }]}
      >
        <Select showSearch placeholder="Select your height">
          {HEIGHT_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Weight"
        name={["physical_details", "weight_in_kg"]}
        rules={[{ required: true, message: "Please select your weight" }]}
      >
        <Select showSearch placeholder="Select your weight">
          {WEIGHT_CHOICES?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default PhysicalDetailsForm;
